<script setup lang="ts">
import type { OfferPreset } from "@/types";

const { t } = useT();
const loginGuard = useLoginGuard();
const { open } = useAppModals();

const props = defineProps<{
	offer: OfferPreset;
}>();

const handleClick = () => {
	loginGuard({
		success: () => {
			const url = props.offer?.promoOfferPreset
				? `/cash/deposit-by-money/${props.offer.promoOfferPreset.id}/promoOffer/`
				: `/cash/deposit-by-money/${props.offer.preset.id}/preset/`;

			window?.$cash?.$router?.push?.(url);
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};
</script>

<template>
	<MMobNavBanner @click="handleClick">
		<AText :modifiers="['semibold']" class="text-cannes">
			{{ t("mobNavBar.promo") }}
		</AText>

		<AAnimationZoomInOut class="logo-wrap" :startScale="1" :finishScale="1.2" :duration="1">
			<NuxtImg
				src="/nuxt-img/promotions/nav-mob.png"
				class="logo"
				height="291"
				width="288"
				format="webp"
				loading="lazy"
				alt="tournaments-nav-mob"
			/>
		</AAnimationZoomInOut>
	</MMobNavBanner>
</template>

<style lang="scss" scoped>
.logo-wrap {
	position: absolute;
	right: -10px;
	bottom: -50px;
}

.logo {
	width: 140px;
	height: auto;
}
</style>
